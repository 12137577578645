
  <div class="col s12">
    <h4>Vet on Site</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->
          <h5>Dr. Patti Maness (DVM)</h5>
          <p>
            Dr. Patti Maness (DVM) graduated from Texas A &amp; M in
            1981, and practiced in south Texas for 20 years. Dr. Maness’
            current practice is south of Oklahoma City, and consists of
            small animal and exotics which includes alpacas. She is a
            member of the American Association of Small Ruminant
            Veterinary Practitioners (AARSP). Dr. Maness is licensed in
            both Texas and Oklahoma. She is the owner of Bowtie Ranch
            and Alpaca Farm. She has owned and shown alpacas since
            2006. She brings a unique perspective as both an owner and
            veterinarian. Dr. Maness has pursued advanced education
            courses regarding alpacas at Kansas State with Dr. David
            Anderson and Dr. Meredyth Jones, as well as Oregon State
            with Dr. Chris Cebra, and just last year at U. C. Davis. Dr.
            Maness is a TXOLAN supporter and member, and will be our
            on-site veterinarian in case her services are needed on a
            fee basis.
          </p>
          <p>
            Her services will be available on Friday in case of health
            certificate (CVI) or microchip issues. Re-issuing a health
            certificate will be $25.00. The ARI certificate and BVD by PCR
            results (lab and date info) will be required. A Bio-Therm Life
            microchip can be implanted for $25.00 if needed.
            In addition, she will will be bringing her ultrasound
            equipment and can perform examinations at a discounted
            cost. You will need to contact her to make proper
            arrangements to access this service as there may be special
            times or requirements needed in order to avoid interference
            with event activities and rules.
          </p>
          <ul>
            <li>Patti S. Maness, DVM</li>
            <li>Maness Veterinary Services</li>
            <li>522 S. W. 27th Place</li>
            <li>Newcastle, OK 73065</li>
            <li>manessvet@yahoo.com</li>
            <li>(405) 387-5005 Newcastle Office</li>
          </ul>














      <!-- <h1>INSERT TITLE HERE</h1>
       -->






          <!-- /main card content -->
        <!-- <div class="card-action">
          <a href="#">Edit</a>
        </div> -->
      </div>
    </div>
  </div>
