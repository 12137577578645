
  <div class="col s12">
    <h4>Board of Directors</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->

        <ul>
          <li><strong>President</strong></li>
          <li>Robert Pulse</li>
          <li>Pulse Ranches Texas</li>
          <li>(817) 996-0117</li>
          <li>txfarr@yahoo.com</li>
        </ul>

        <ul>
          <li><strong>Vice-President</strong></li>
          <li>Gina Glasscock</li>
          <li>Legendary Alpacas</li>
          <li>(469) 834-5504</li>
          <li>gina@legendaryalpacas.com</li>
        </ul>

        <ul>
          <li><strong>Treasurer</strong></li>
          <li>Phil McMahon</li>
          <li>Peeka Ranch Alpacas</li>
        </ul>

        <ul>
          <li><strong>Secretary</strong></li>
          <li>Amy Yoste</li>
          <li>Rockin' Y Ranch Alpacas</li>
          <li>(972) 741-0382</li>
          <li>amy.yoste@gmail.com</li>
        </ul>

        <ul>
          <li><strong>At Large</strong></li>
          <li>Charles Ashley</li>
          <li>Aubrey Oaks Alpacas</li>
          <li>425 Woodbine Estates Road</li>
          <li>Gainesville, TX 76240</li>
          <li>940-668-4099</li>
          <li>Charles.Ashley@aubreyoaksalpacas.com</li>
        </ul>

        <ul>
          <li><strong>Immediate Past President (non-voting consulting director)</strong></li>
          <li>Ray McDonald</li>
          <li>McPaca Ranch, Inc.</li>
          <li>(817) 239-0725</li>
        </ul>



        <!-- <h1>INSERT TITLE HERE</h1>


        <ul>
          <li *ngFor="let d of directors">
            <ul>
              <li>
                <strong>{{ d.title }}</strong>
              </li>
              <li>
                <strong>{{ d.firstName }} {{ d.lastName }}</strong>
              </li>
              <li>
                {{ d.companyName }}
              </li>
              <li>
                {{ d.phone }}
              </li>
              <li>
                {{ d.email }}
              </li>
            </ul>
          </li>
        </ul> -->







          <!-- /main card content -->
        <!-- <div class="card-action">
          <a href="#">Edit</a>
        </div> -->
      </div>
    </div>
  </div>
