import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit, OnDestroy {


  constructor() {}

  ngOnInit() {
  }

  onLogout() {
  }

  ngOnDestroy() {
  }
}
