



<header>
  <nav class="nav-extended">
    <div class="nav-wrapper teal lighten-1">
      <a routerLink="home" class="brand-logo left">TXOLAN <span class="lime-text">2021</span></a>
      <a href="" data-target="mobile-demo" class="sidenav-trigger right"><i class="material-icons">menu</i></a>
    </div>







    <div class="nav-content teal hide-on-med-and-down">

      <ul class="tabs tabs-transparent">



        <li class="tab">
          <a class='dropdown-trigger' href='#' data-target='subnav_show_info'>Show Info</a>


          <ul id='subnav_show_info' class='dropdown-content grey darken-2'>
            <li><a routerLink="schedule_of_events" class="white-text" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Schedule</a></li>
            <li><a routerLink="event_map" class="white-text" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Event Map</a></li>
            <li><a routerLink="fleece_show" class="white-text" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Fleece Show</a></li>
            <li><a routerLink="halter_show" class="white-text" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Halter Show</a></li>
            <li><a routerLink="judges" class="white-text" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Judges</a></li>
            <li><a routerLink="vet_on_site" class="white-text" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Vet On Site</a></li>
          </ul>
        </li>

        <li class="tab"><a routerLink="speakers" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Event Speakers</a></li>
        <li class="tab"><a routerLink="sponsors" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Sponsors</a></li>
        <li class="tab"><a routerLink="vendors" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Vendors</a></li>
        <li class="tab"><a routerLink="volunteers" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Volunteers</a></li>

        <li class="tab">
          <a class='dropdown-trigger' href='#' data-target='subnav_about_txolan'>About TXOLAN</a>

          <ul id='subnav_about_txolan' class='dropdown-content grey darken-2'>
            <li><a routerLink="message_from_the_president" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">President's Message</a></li>
            <li><a routerLink="board_of_directors" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Board of Directors</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>







  <ul id="mobile-demo" class="sidenav">
    <li>
      <ul class="collapsible collapsible-accordion">
        <li class="bold"><a class="collapsible-header waves-effect waves-teal">Show Info</a>
          <div class="collapsible-body">
            <ul>
              <li><a routerLink="schedule_of_events" class="white-text sidenav-close waves-effect waves-black" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Schedule</a></li>
              <li><a routerLink="event_map" class="white-text sidenav-close waves-effect waves-black" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Event Map</a></li>
              <li><a routerLink="fleece_show" class="white-text sidenav-close waves-effect waves-black" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Fleece Show</a></li>
              <li><a routerLink="halter_show" class="white-text sidenav-close waves-effect waves-black" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Halter Show</a></li>
              <li><a routerLink="judges" class="white-text sidenav-close waves-effect waves-black" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Judges</a></li>
              <li><a routerLink="vet_on_site" class="white-text sidenav-close waves-effect waves-black" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Vet On Site</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="divider"></div>
    </li>
    <li class="tab"><a routerLink="speakers" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" class="sidenav-close waves-effect waves-teal">Event Speakers</a></li>
    <li>
      <div class="divider"></div>
    </li>
    <li class="tab"><a routerLink="sponsors" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" class="sidenav-close waves-effect waves-teal">Sponsors</a></li>
    <li>
      <div class="divider"></div>
    </li>
    <li class="tab"><a routerLink="vendors" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" class="sidenav-close waves-effect waves-teal">Vendors</a></li>
    <li>
      <div class="divider"></div>
    </li>
    <li class="tab"><a routerLink="volunteers" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" class="sidenav-close waves-effect waves-teal">Volunteers</a></li>
    <li>
      <div class="divider"></div>
    </li>
    <li>
      <ul class="collapsible collapsible-accordion">
        <li class="bold"><a class="collapsible-header waves-effect waves-teal">About TXOLAN</a>
          <div class="collapsible-body">
            <ul>
              <li><a routerLink="message_from_the_president" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" class="sidenav-close waves-effect waves-black">President's Message</a></li>
              <li><a routerLink="board_of_directors" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" class="sidenav-close waves-effect waves-black">Board of Directors</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </li>

  </ul>


</header>
