import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";

// import { PagessService } from "../pagess.service";
// import { Pages } from "../pages.model";
// import { mimeType } from "./mime-type.validator";
//

@Component({
  selector: "app-pages-sponsors",
  templateUrl: "./sponsors.component.html",
  styleUrls: ["./sponsors.component.css"]
})
export class SponsorsPageComponent implements OnInit {

  isLoading = false;
  sponsors;
  sponsorsList;
  private sponsorsSub: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute
  ) {};

  ngOnInit() {



    this.isLoading = true;


    this.sponsorsList = [
      {"name":"Alpaca Country Estates","owner_name":"Nancy Chapel-Izer","address":"70397 Buckhorn Rd.","address2":"","city":"Terrebonne","state":"OR","zip":"97760","country":"United States","phone":"(541) 504-4226","mobile":"(541) 272-8016","fax":"(541) 504-4226","website":"http://alpacacountryestates.com/","email":"alpacacountryestates@yahoo.com"},
      {"name":"Alpacas by Melodye of Bountiful Acres","owner_name":"Melodye McLeroy","address":"PO Box 41","address2":"","city":"Thackerville","state":"OK","zip":"73459","country":"United States","phone":"(940) 768-8055","mobile":"","fax":"","website":"","email":"rrmcleroy@gmail.com"},
      {"name":"Alpacas of Oklahoma","owner_name":"Debbie Ashley","address":"425 Woodbine Estates Rd.","address2":"","city":"Gainesville","state":"TX","zip":"76240","country":"United States","phone":"(940) 668-4099","mobile":"","fax":"","website":"www.alpacasofoklahoma.com","email":"aok.pres@gmail.com"},
      {"name":"Crescent Moon Ranch","owner_name":"Scott & Debbie Miller","address":"7566 N Hwy 97","address2":"PO Box 600","city":"Terrebonne","state":"OR","zip":"97760","country":"United States","phone":"(541) 923-2285","mobile":"(360) 317-4752","fax":"","website":"www.crescentmoonranch.com","email":"scott@crescentmoonranch.com"},
      {"name":"Dos Chicas Ranch","owner_name":"Kayla Franke","address":"3066 Mt. Zion Rd.","address2":"","city":"Midlothian","state":"TX","zip":"76065","country":"United States","phone":"(214) 914-8147","mobile":"","fax":"","website":"","email":"doschicastexas@gmail.com"},
      {"name":"EZid, LLC","owner_name":"Elsie McCoy","address":"","address2":"","city":"","state":"","zip":"","country":"","phone":"","mobile":"","fax":"","website":"www.EZidAvid.com","email":""},
      {"name":"Flying Iron Ranch","owner_name":"Christine Brown & Jim Head","address":"9110 Rockbrook Dr","address2":"","city":"Dallas","state":"TX","zip":"75220","country":"United States","phone":"(214) 450-9452","mobile":"(214) 450-9452","fax":"","website":"www.flyingironranch.com","email":"alpacas@flyingironranch.com"},
      {"name":"Foster's Trail & Alpaca Farm","owner_name":"Susan & Peter Goodwin","address":"5749 North Lee Hwy","address2":"N Lee Hwy","city":"Cleveland","state":"TN","zip":"37312","country":"United States","phone":"(423) 559-9100","mobile":"(423) 310-6551","fax":"","website":"www.fosterstrailalpacas.com","email":"ftaf@fosterstrailalpacas.com"},
      {"name":"Heart & Soul Alpacas","owner_name":"Russell Ratti & Karen Ball","address":"26 Bench Road","address2":"","city":"Montana","state":"MT","zip":"59759","country":"United States","phone":"(530) 559-7223","mobile":"","fax":"","website":"heartandsoulspinnery.com","email":"heartandsoulalpacas@gmail.com"},
      {"name":"Heartfelt Alpaca Creations","owner_name":"Gary & Mary Licklider","address":"6701 W. Gillespie Bridge Rd.","address2":"","city":"Columbia","state":"MO","zip":"65203","country":"United States","phone":"(573) 819-4685","mobile":"","fax":"","website":"www.curlyeye.com","email":"info@heartfeltalpaca.com"},
      {"name":"Legendary Alpacas of Texas","owner_name":"Gina Glasscock","address":"574 EP Dawson Rd.","address2":"","city":"Maypearl","state":"TX","zip":"76064","country":"United States","phone":"(469) 834-5504","mobile":"(469) 834-5504","fax":"","website":"legendaryalpacas.com","email":"gina@legendaryalpacas.com"},
      {"name":"Little Creek Farm","owner_name":"Lynn Edens","address":"321 Hardscrabble Road","address2":"","city":"North Salem","state":"NY","zip":"10560","country":"United States","phone":"(917) 952-5184","mobile":"(917) 952-5184","fax":"","website":"www.lcfalpacas.com","email":"lynn@lcfalpacas.com"},
      {"name":"Mazuri Exotic Animal Nutrition","owner_name":"Liza Reid","address":"100 Danforth Drive","address2":"","city":"Gray Summit","state":"MO","zip":"63039","country":"United States","phone":"(636) 742-6143","mobile":"","fax":"","website":"mazuri.com","email":"Info@mazuri.com"},
      {"name":"Odin Falls Alpacas","owner_name":"Allen Kallel","address":"9101 NW 31st St.","address2":"","city":"Oregon","state":"OR","zip":"97760","country":"United States","phone":"(541) 410-2324","mobile":"(541) 410-2324","fax":"","website":"","email":"allenkallel@gmail.com"},
      {"name":"Peeka Ranch","owner_name":"Philip & La Nell McMahon","address":"13350 Fm 389","address2":"","city":"Burton","state":"TX","zip":"77835","country":"United States","phone":"(713) 857-1461","mobile":"","fax":"(713) 462-4715","website":"www.peekaranch.com","email":"pmcmahon@peekaranch.com"},
      {"name":"Pulse Ranches Texas","owner_name":"Nancy & Robert Pulse","address":"1154 Cr 4360","address2":"","city":"Decatur","state":"TX","zip":"76234","country":"United States","phone":"(940) 393-1483","mobile":"(940) 393-1483","fax":"","website":"www.alpacasamerica.com","email":"nancypulse@gmail.com"},
      {"name":"Red Granite Ranch Ltd.","owner_name":"Marc & Sharon Milligan","address":"1021 Red Granite Ranch Rd","address2":"","city":"Livermore","state":"CO","zip":"80536","country":"United States","phone":"(303) 906-4279","mobile":"(303) 906-4279","fax":"","website":"www.redgraniteranch.com","email":"rgralpacas@hughes.net"},
      {"name":"Structured Chaos","owner_name":"Wendi & Todd Eccles","address":"3792 Cr 1125","address2":"","city":"Farmersville","state":"TX","zip":"75442","country":"United States","phone":"(916) 532-7747","mobile":"","fax":"","website":"www.structuredchaosfarm.com","email":"structuredchaosfarm@gmail.com"},
      {"name":"Tierra Prometida Alpacas","owner_name":"Rhonda & Kern Deschner","address":"4805 Fm 32","address2":"","city":"Fischer","state":"TX","zip":"78623","country":"United States","phone":"(512) 753-9962","mobile":"(512) 753-9962","fax":"","website":"www.alpacausa.com","email":"rhondadeschner@gmail.com"},
      {"name":"Xanadu Farm Alpacas","owner_name":"Pamela Ray","address":"1531 Preserve Drive","address2":"","city":"Frederick","state":"CO","zip":"80504","country":"United States","phone":"(303) 902-1741","mobile":"(303) 902-1741","fax":"","website":"xanadualpacas.com","email":"pamela@xanadualpacas.com"}
      ];


    // this.userIsAuthenticated = this.authService.getIsAuth();
    // this.authStatusSub = this.authService
    //   .getAuthStatusListener()
    //   .subscribe(isAuthenticated => {
    //     this.userIsAuthenticated = isAuthenticated;
    //     this.userId = this.authService.getUserId();
    //   });
  }

}
