import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";

// import { JudgesService } from "src/app/judges/judges.service";
// import { Judge } from "src/app/judges/judge.model";

// import { PagessService } from "../pagess.service";
// import { Pages } from "../pages.model";
// import { mimeType } from "./mime-type.validator";
//

@Component({
  selector: "app-pages-judges",
  templateUrl: "./judges.component.html",
  styleUrls: ["./judges.component.css"]
})
export class JudgesPageComponent implements OnInit {

  // judges: Judge[];
  isLoading = false;
  // private judgesSub: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute
  ) {};

  ngOnInit() {



    // this.judgesService.getJudgesByEvent(this.dashboard.event.id);
    // this.judgesSub = this.judgesService
    //   .getJudgeUpdateListener()
    //   .subscribe((judgeData: { judges: Judge[]; judgeCount: number }) => {
    //     this.isLoading = false;
    //     this.judges = judgeData.judges;
    //   });

  }

}
