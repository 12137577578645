
  <div class="col s12">
    <h4>Speakers</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->
<!--
        <div class="center-align hide-on-large-only">
          <h5>Featured Speaker</h5>
        </div>
        <div class="hide-on-med-and-down">
          <h5>Featured Speaker</h5>
        </div>
        <hr/> -->
        <div class="row">
          <div class="col s12 l4">
            <div class="center-align">
              <img src="assets/img/speakers/gease-wade.jpg">
            </div>
          </div>
          <div class="col s12 l8">
            <h5>Wade Gease</h5>
            <!-- <ul>
              <li><strong>Senior Halter Judge & Judge Trainer for All Certifications</strong></li>
              <li><strong>Certifications:</strong></li>
              <li><strong>Huacaya Halter, Suri Halter, Fleece, Walking Fleece, Performance</strong></li>
            </ul> -->
            <p>Wade Gease is an AOA certified senior alpaca judge, judge training instructor, presenter, designer and consultant/mediator for the worldwide alpaca industry. He was the previous co-owner of LondonDairy Alpacas for 18 years. He now unveils his own wholesale/retail product line as The Alpaca Guy.</p>
            <p>Wade has been honored to be involved with so many different activities in the alpaca world: presenting his personal educational seminars at pen sales, consulting, commentating, coordinating for auctions, and evaluating for private alpaca breeders. A favored activity has been leading yearly educational trips throughout Peru. In 2021 he will host a Fiesta Alpaca trip in April because of a scheduled alpaca show in Cusco. Contact him to be put on his notification list.</p>
            <p>Wade was the first to produce a N.A. made alpaca bamboo fabric. Thereafter, he created an upscale clothing line highlighting alpaca fabric, Alpaca Couture. The company’s vision was to gain greater attention from a discriminating consumer and help the industry gain greater recognition as an attractive fiber for fashion. Fashion shows were presented in Las Vegas, Orange County, and San Diego. The business transitioned into the accessory lines of The Alpaca Guy. Wade helps with Fashion Show/ Auction coordinations at different venues and shows like AOA.</p>
            <p>Mr. Gease also forged ahead in making alpaca materials, designing alpaca products and has created a wet felting machine for home/farm use to empower ranches to make on the farm products, The Wet Felting Wizard. It has had over 25,000 views on YouTube and has sold as far as the Netherlands, France & South Africa.</p>
            <p>Wade earned an Associate’s Degree in Aeronautical Science, a Bachelor’s Degree in Chemistry, and a Masters Degree in Biochemistry. He is also a Camelid Fibre Sorter certified from Olds College Natural Fibre Centre, Canada. His past has given him experience in construction, was a certified flight instructor, chemistry instructor, co-owner of a gift and floral store, and a professional sales representative for multiple pharmaceutical companies. He enjoys scuba diving, home improvement projects, and anything related to alpaca fiber!</p>
          </div>
        </div>
        <div class="row">
          <div class="col s12 l4">
            <div class="center-align">
              <img src="assets/img/speakers/char.jpg">
            </div>
          </div>
          <div class="col s12 l8">
            <h5>Char Johnson</h5>
            <!-- <ul>
              <li><strong>Senior Halter Judge & Judge Trainer for All Certifications</strong></li>
              <li><strong>Certifications:</strong></li>
              <li><strong>Huacaya Halter, Suri Halter, Fleece, Walking Fleece, Performance</strong></li>
            </ul> -->
            <p>Char and Rick have owned Happy Tails Alpacas for over 14 years. They have recently relocated from China Grove, NC to Xenia, OH to be near family. Their alpacas now reside on their business partner's farm nearby. Char has always been active in the industry by volunteering at shows and serving as Secretary and President of the Carolina Alpaca Breeders and Owners. She has been speaker and lead seminars at the AOA Fiber Extravaganza. Char has always been a creative spirit and is an accomplished pianist. She loves all things fiber including dying, spinning, felting and weaving. January 2021, Char began serving as a member of the AOA Board of Directors.</p>
          </div>
        </div>

        <div class="row">
          <div class="col s12 l4">
            <div class="center-align">
              <img src="assets/img/speakers/staci.jpg">
            </div>
          </div>
          <div class="col s12 l8">
            <h5>Staci Forshee</h5>
            <!-- <ul>
              <li><strong>Senior Halter Judge & Judge Trainer for All Certifications</strong></li>
              <li><strong>Certifications:</strong></li>
              <li><strong>Huacaya Halter, Suri Halter, Fleece, Walking Fleece, Performance</strong></li>
            </ul> -->
            <p>My family got into the ‘llama business’ back in 1997, with the purchase of a male and female (because that’s just what new owners without a clue did). I jumped into the whole ordeal with both feet, learning how to train, handle, shear, and care for the llamas as much as I could on my own. When I got into high school, I pestered my grandparents relentlessly for an alpaca, and in 2002, I acquired Zydaco, a two-year-old gelded male Huacaya. He started me on this long, strange trip into the world of alpacas, and now I can’t imagine living without them. I currently own a herd of 30, a mixture of both llamas and alpacas, huacayas and suris. I’ve worked as a farm manager for the past 10 years, and recently started focusing more on the fleece side of things as the manager of a new fiber mill in Oklahoma. But, training and working directly with the alpacas will always be my passion, and I enjoy sharing the things I’ve learned over the years with those who wish to ‘do more’ with their animals.</p>
          </div>
        </div>










        <!-- <h1>INSERT TITLE HERE</h1>


        <ul>
          <li *ngFor="let s of speakers">
            <ul>
              <li>
                <img [src]="s.imagePath" [alt]="s.lastName">
              </li>
              <li>
                <strong>{{ s.firstName }} {{ s.lastName }}</strong>
              </li>
              <li [innerHTML]="s.bio">
              </li>
            </ul>
          </li>
        </ul> -->




          <!-- /main card content -->
        <!-- <div class="card-action">
          <a href="#">Edit</a>
        </div> -->
      </div>
    </div>
  </div>
