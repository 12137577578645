<h4>INSERT TITLE HERE</h4>


<div class="row">
  <div class="col s12">
    <div class="row">
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">Total Sponsors</span>
            <div class="valign-wrapper">
              <h1>10</h1>
            </div>

            <div class="card-action">
              <a href="#">Edit</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">Ads Rejected</span>
            <div class="valign-wrapper">
              <h1 class="center-align">3</h1>
            </div>
          </div>
          <div class="card-action">
            <a href="#">Edit</a>
          </div>
        </div>
      </div>
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">Ads Received</span>
            <div class="valign-wrapper">
              <h1>5/10</h1>
            </div>
          </div>
          <div class="card-action">
            <a href="#">Edit</a>
          </div>
        </div>
      </div>
      <div class="col s12">
        <div class="card white darken-1">
          <div class="card-content">
            <span class="card-title">Sponsor List</span>
            <table>
              <thead>
                <tr>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Sponsorship Level</th>
                    <th>Digital Ad</th>
                    <th>Print Ad</th>
                    <th></th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td><i class="small material-icons red-text">report_problem</i></td>
                  <td>Sponsor One</td>
                  <td>Diamond</td>
                  <td><i class="small material-icons">close</i></td>
                  <td><i class="small material-icons">check</i></td>
                  <td>EDIT</td>
                </tr>
                <tr>
                  <td><i class="small material-icons green-text accent-4">check_circle</i></td>
                  <td>Sponsor Two</td>
                  <td>Ruby</td>
                  <td><i class="small material-icons">check</i></td>
                  <td><i class="small material-icons">check</i></td>
                  <td>EDIT</td>
                </tr>
                <tr>
                  <td><i class="small material-icons red-text">report_problem</i></td>
                  <td>Sponsor Three</td>
                  <td>Gold</td>
                  <td><i class="small material-icons">close</i></td>
                  <td><i class="small material-icons">check</i></td>
                  <td>EDIT</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-action">
            <a href="#">Add Sponsor</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


