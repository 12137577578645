
  <div class="col s12">
    <h4>Vendors</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->


        <div class="row flex">
          <div class="col xl4 l6 s12">
            <ul>
              <li><strong>Acres of Love Alpaca Ranch</strong></li>
              <li>Jess & Cookie Bowers</li>
              <li>11006 N 92nd East Ave</li>
              <li>Owasso OK  74055 </li>
              <li>(918) 829-9987 </li>
              <li>www.acresoflove.com</li>
              <li>cookie@acresoflove.com</li>
            </ul>
          </div>
          <div class="col xl4 l6 s12">
            <ul>
              <li><strong>Alpacas of Greater TN</strong></li>
              <li>Paula Buchner </li>
              <li>10925 Hwy 58 </li>
              <li>Georgetown TN  37336 </li>
              <li>(423) 667-3374 </li>
              <li>www.alpacanation.com/agt.asp </li>
              <li>greatertnalpacas@bellsouth.net</li>
            </ul>
          </div>
          <div class="col xl4 l6 s12">
            <ul>
              <li><strong>Blue Marble Pen Pals</strong></li>
              <li>Susan J Uhl </li>
              <li>2843 Mira Vista Lane </li>
              <li>Rockwall TX  75032 </li>
              <li>(469) 388-5727 </li>
              <li>BlueMarblePenPals.org </li>
              <li>BMarblePenPals@gmail.com</li>
            </ul>
          </div>
          <div class="col xl4 l6 s12">
            <ul>
              <li><strong>Crossed Arrows, LLC</strong></li>
              <li>Russ & Brenda Wyler </li>
              <li>Po Box 69 </li>
              <li>Velma OK  73491</li>
              <li>(540) 295-8214 </li>
              <li>crossedarrows_alpacas.com </li>
              <li>crossedarrows@comcast.net</li>
            </ul>
          </div>
          <div class="col xl4 l6 s12">
            <ul>
              <li><strong>International Gift (vendor)</strong></li>
              <li>Ana Sanguineti </li>
              <li>15074 Preston Hollow</li>
              <li>TX, TX  78247 </li>
              <li>(210) 232-3402 </li>
              <li>shopinternationalgift.com </li>
              <li>luansanguineti@yahoo.com</li>
            </ul>
          </div>
          <div class="col xl4 l6 s12">
            <ul>
              <li><strong>Prairie States Insurance Agency</strong></li>
              <li>Patricia Jackson </li>
              <li>1216 N.w. 50th </li>
              <li>Oklahoma City OK  73118 </li>
              <li>(800) 627-4677 </li>
              <li>(405) 235-5541 </li>
              <li>prairiestatesinsurance.com </li>
              <li>patty_jackson@prairiestatesinsurance.com</li>
            </ul>
          </div>
          <div class="col xl4 l6 s12">
            <ul>
              <li><strong>Red Hill Fiber Mill & Farm</strong></li>
              <li>Ty & Lindsey Higgins </li>
              <li>2708 N Red Hill Rd </li>
              <li>Taswell IN  47175 </li>
              <li>(812) 788-1165 </li>
              <li>www.redhillfiber.com </li>
              <li>Lindsey@redhillfiber.com</li>
            </ul>
          </div>
          <div class="col xl4 l6 s12">
            <ul>
              <li><strong>Winterstrom Ranch LLC</strong></li>
              <li>Sarah Winterstrom </li>
              <li>1180 State Hwy 132 N </li>
              <li>Natalia TX  78059 </li>
              <li>(209) 628-5426</li>
              <li>Winterstromranch.com </li>
              <li>Winterstromranch@gmail.com</li>
            </ul>
          </div>
          <div class="col xl4 l6 s12">
          </div>
        </div>

















<!--
        <h1>INSERT TITLE HERE</h1>

        <ul>
          <li *ngFor="let v of vendors">
            <ul>
              <li>
                <img [src]="v.imagePath" [alt]="v.companyName">
              </li>
              <li>
                <strong>{{ v.companyName }}</strong>
              </li>
              <li>
                {{ v.firstName }} {{ v.lastName }}
              </li>
              <li>
                <div>{{ v.address1 }}</div>
                <div *ngIf="v.address2">{{ v.address2 }}</div>
                <div>{{ v.city }}, {{ v.state }} {{ v.zipCode }}</div>
              </li>
              <li>
                {{ v.phone }}
              </li>
              <li>
                {{ v.email }}
              </li>
              <li>
                {{ v.url }}
              </li>
            </ul>
          </li>
        </ul> -->




          <!-- /main card content -->
        <!-- <div class="card-action">
          <a href="#">Edit</a>
        </div> -->
      </div>
    </div>
  </div>



