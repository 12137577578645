<h1>INSERT TITLE HERE</h1>


<hr />

<h4>If you are a:</h4>


<div class="row">
  <div class="col s12">
    <div class="card white darken-1">
      <div class="card-content">
        <span class="card-title">Sponsor Steps</span>
        <p>Do this if you're a sponsor...</p>
        <ol>
          <li>step one</li>
        </ol>
      </div>
    </div>
    <div class="card white darken-1">
      <div class="card-content">
        <span class="card-title">Show Admin Steps</span>
        <p>Do this if you're a show admin...</p>
        <ol>
          <li>step one</li>
          Create account
          Click Sponsors admin tab IMAGE HERE
          Click List Sponsors to see list of sponsors.
          Click a sponsor in the list to edit.
          To upload an advertisment for the sponsor, click here while editing.
          Click Add Sponsor to add a new sponsor.

        </ol>
      </div>
    </div>
  </div>
</div>




<div class="row">
  <div class="col s12">
    <div class="row">
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">User Info</span>
            <p>name: John Doe</p>
            <p>username: johndoe@johndoefarms.com</p>
            <p>password: *********</p>
          </div>
          <div class="card-action">
            <a href="#">Edit</a>
          </div>
        </div>
      </div>
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">Vendor Business Info</span>
            <p>
              Business/Ranch Name
            </p>
            <ul>
              <li>
                address 1<br />
                address 2<br />
                city, state zip
              </li>
              <li>
                phone number
              </li>
              <li>
                email
              </li>
              <li>
                http://www.website.com
              </li>
            </ul>
          </div>
          <div class="card-action">
            <a href="#">Edit</a>
          </div>
        </div>
      </div>
