import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";


// import { HomesService } from "../homes.service";
// import { Home } from "../home.model";
// import { mimeType } from "./mime-type.validator";
//

@Component({
  selector: "app-pages-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomePageComponent implements OnInit {



  constructor(
    private activatedRoute: ActivatedRoute,

  ) {};

// @Input('dashboard') dashboard: Dashboard;

  ngOnInit() {

  }

}
