<h1>INSERT TITLE HERE</h1>




<div class="row">
  <div class="col s12">
    <div class="row">
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">User Info</span>
            <p>name: John Doe</p>
            <p>username: johndoe@johndoefarms.com</p>
            <p>password: *********</p>
          </div>
          <div class="card-action">
            <a href="#">Edit</a>
          </div>
        </div>
      </div>
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">Vendor Business Info</span>
            <p>
              Business/Ranch Name
            </p>
            <ul>
              <li>
                address 1<br />
                address 2<br />
                city, state zip
              </li>
              <li>
                phone number
              </li>
              <li>
                email
              </li>
              <li>
                http://www.website.com
              </li>
            </ul>
          </div>
          <div class="card-action">
            <a href="#">Edit</a>
          </div>
        </div>
      </div>
