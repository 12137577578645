
  <div class="col s12">
    <h4>Message from the President</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->



        <p>Welcome to all folks that have traveled to Wichita Falls, Texas for our 2021 TXOLAN Event! We,
        the Board of Directors, as well as the members of TXOLAN Alpaca Association, Inc. are pleased
        and excited to have you attend our little soiree and hope that it meets your expectations, that
        you are successful in the show ring, that you learn something new, that you meet a new friend
        and that you have a rootin’ tootin’ good time while doing it.</p>
        <p>We look forward to meeting and visiting with each and every one of you. Since this is our first
        year to hold our TXOLAN Event at the J. S. Bridwell Agricultural Center, we all will be going
        through somewhat of a learning phase as we experience it and get to know about it and
        Wichita Falls together. If you run into difficulties during your stay, do not hesitate to notify one
        of the volunteers or Board Members and ask for help.</p>
        <p>Please give a good read to our posters regarding our position as it relates to the Covid19 Virus.
        We will be following State of Texas guidelines regarding social distancing, masks and sanitation,
        so if we all make a concerted effort to adhere to these guidelines our chances of contracting
        the Virus will be lessened considerably. For your convenience, we will locate hand sanitation
        pumps, etc. around the facility and if you forget your mask, we should have some extra ones
        available.</p>
        <p>The weather forecast calls for some cool weather, but not to worry, our facilities are heated,
        we can check in and load out from the inside and you can park your rigs up close if you like.
        Also, for those of you that do not want to have to rush to get on the road Sunday night, please
        feel free to lay over and load out by 9:30 Monday morning.</p>
        <p>Thank you in advance for helping make our Event this year a great one! We attained a level III
        show rating in both the Sweetheart Spectacular and the Bluebonnet Stakes and about 105
        Fleece Show entries.</p>
        <p>Travel safely and let us know you made it home in good shape!!</p>
        <p>Best regards,</p>
        <ul>
          <li>Bob Pulse, President</li>
          <li>TXOLAN Alpaca Association, Inc</li>
          <li>txfarr@yahoo.com</li>
          <li>817-996-0117</li>
        </ul>






      <!-- <h1>INSERT TITLE HERE</h1>
       -->





          <!-- /main card content -->
        <!-- <div class="card-action">
          <a href="#">Edit</a>
        </div> -->
      </div>
    </div>
  </div>
