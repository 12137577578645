import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";


// import { PagessService } from "../pagess.service";
// import { Pages } from "../pages.model";
// import { mimeType } from "./mime-type.validator";
//

@Component({
  selector: "app-pages-halter-show",
  templateUrl: "./halter_show.component.html",
  styleUrls: ["./halter_show.component.css"]
})

export class HalterShowPageComponent implements OnInit {

  activePanel = "1";
  constructor(
    private activatedRoute: ActivatedRoute,

  ) {};

  ngOnInit() {

  }

  activateShowPanelOne() {
    this.activePanel = "one";
  }
  activateShowPanelTwo() {
    this.activePanel = "two";
  }
  activateShowPanelThree() {
    this.activePanel = "three";
  }

  activateShowPanel(panel) {
    this.activePanel = panel;
  }

  showSelectionChange(event: any) {
    this.activePanel = event.target.value;
  }

}

