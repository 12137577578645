<div class="card advertisement">
  <div class="card-content">
    <div class="center-align">
      <a *ngIf="currentAdUrl" href="{{ currentAdUrl }}" >
        <img src="{{ currentAdImgUrl }}" alt="{{ currentAdName }}" title="{{ currentAdName }}" />
      </a>
      <img *ngIf="!currentAdUrl" src="{{ currentAdImgUrl }}" alt="{{ currentAdName }}" title="{{ currentAdName }}" />
      <div class="grey-text">
        Event Sponsor
      </div>
    </div>
  </div>
</div>
