

<div class="content">
  <app-header2></app-header2>

  <main>
    <div>Publicly Available Pages Wrapper:</div>
    <ul>
      <li *ngFor="let link of navigationList">
        <a routerLink="{{link.url}}" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}"> {{ link.title }} </a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </main>

  <app-footer></app-footer>
</div>
