<!--

  <div class="col s12">
    <h1>INSERT TITLE HERE</h1>

  </div>
  <div class="col s12">
    <div class="card">
      <div class="card-content">
        <p>Welcome to the 2021 TXOLAN Sweetheart Spectacular!</p>
        <p>Please use our interactive application to learn more about our 2021 event. <span class="show-on-medium-and-down">To start, please use the navigation in the top right corner of your screen to begin your browsing experience.</span></p>
      </div>
    </div>
  </div> -->





  <div class="col s12">
    <h4>Home</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->

        <p>Welcome to the 2021 TXOLAN Sweetheart Spectacular!</p>
        <p>Please use our interactive application to learn more about our 2021 event. </p>
        <div class="hide-on-large-only">To start, please use the navigation in the top right corner of your screen to begin your browsing experience.</div>
        <p>Alternatively, you can download and browse the PDF version of our showbook below.</p>
        <div>
          <a href="assets/pdf/txolan2021showbook.pdf"><img src="assets/img/site/pdf_snapshot.png" /></a>
        </div>

          <!-- /main card content -->
      </div>
    </div>
  </div>
