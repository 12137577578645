import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";


// import { PagessService } from "../pagess.service";
// import { Pages } from "../pages.model";
// import { mimeType } from "./mime-type.validator";
//

@Component({
  selector: "app-pages-shedule-of-events",
  templateUrl: "./schedule_of_events.component.html",
  styleUrls: ["./schedule_of_events.component.css"]
})
export class ScheduleOfEventsPageComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,

  ) {};

  ngOnInit() {

  }

}
