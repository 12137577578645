  <div class="col s12">
    <h4>Halter Show</h4>
  </div>

  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->

          <h5>Youth Performance</h5>
          <ul>
            <li>
              Showmanship, which places emphasis on the handler’s show abilities
            </li>
            <li>Obstacle, which demonstrates the alpaca’s ability and willingness to accept
              training and to work with its handler through a variety of obstacles
            </li>
            <li>Public Relations, which demonstrates the alpaca’s and handler’s ability and
              willingness to perform day to day obstacles that may be encountered when
              doing public relations events with your alpacas
            </li>
            <li>
              Costume Class
            </li>
          </ul>
          <h5>Show Rules</h5>
          <ul>
            <li>
              Online Registration must be completed.
            </li>
            <li>
              Alpacas must be registered with AOA; NO Pending ARI Registration Papers
          will be accepted.
            </li>
            <li>
              Entries must include copy of AOA Certificate, Buyer’s Permission to Show if
              applicable, completed registration and full registration fees. Buyer’s Permission
              to Show form can be found at www.alpacashows.com.
            </li>
            <li>
              Entries must be registered online by 1/31/20.
            </li>
            <li>
              Veterinary documents required must be included with entries. For AOA
          registered animals, there does need to be an identifying tag or number to
          be able to verify animal presented is the same as noted on the veterinary
          certificate.
            </li>
            <li>
              To be allowed entry to the show, the owner shall submit a copy of the Certificate
          of Veterinary Inspection (Health Certificate), which shall include the AOA
          number, alpaca name as on the ARI certificate, microchip #, and negative
          BVD results with testing method for each alpaca entering the show grounds,
          including companion or private treaty alpacas. The Show Superintendent
          will keep a copy of the Certificate of Veterinary Inspection as permanent
          records for the show.
            </li>
            <li>
              All alpacas entering the show grounds must show proof of a negative PCR
          test or a negative VI (VI prior to 1/1/2010) test for BVD (NO other types of
          BVD testing will be accepted).
            </li>
            <li>
              The Halter Show Class Divisions 501 to 510 (Get of Sire; Produce of Dam;
          Breeders Best Three; Bred &amp; Owned Male; Bred &amp; Owned Female; and Produce
          of Dam) are Optional Halter classes; entries in these groups REQUIRE
          that your alpaca ALSO be entered in the appropriate Age-Color-Gender-
          Breed Halter class. (See Optional Halter Classes, Part VIII, Section 6, AOA
          Show Division Handbook)
            </li>
            <li>
              Exhibitor Rule Halter Show: The owner may enter only 3 alpacas per breed
              type, per gender, per age division, per color designation.
            </li>
            <li>
              Classes may be divided or combined according to AOA show rules, depending
              on the number of entries.
            </li>
            <li>
              Color designation refers to one of the colors shown in the current ARI Color
          Chart plus the multicolor types described in the Part VII, Section 2, AOA
          Show Division Handbook. Please use actual color of fiber at skin line, regardless
          of what your ARI certificate says. All colors will be verified at check-in
          according to the ARI Color Chart.
            </li>
          </ul>
          <h5>What is a Walking Fleece Competition?</h5>
          <p>Walking Fleece Competition is an opportunity for the exhibitor to
          enter a fleece competition where the prime fleece is evaluated while
          still on the alpaca. Conformation is NOT assessed. An AOA Walking
          Fleece scorecard will be completed for each alpaca in the course of
          this evaluation/judging process. Placements are made on a highest
          score basis once all evaluations are completed and tabulated. Oral
          comments by the Judge may be given during the hands-on evaluation.
          Judging by an AOA Certified Fleece Judge is required.
          Walking Fleece Registrations are handled as
          an Optional Halter Class via the registration System
          but will follow the rules for a Fleece Show.</p>


        <!-- /main card content -->
      </div>
    </div>
  </div>
