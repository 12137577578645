import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { Header2Component } from "./header2/header2.component";
import { FooterComponent } from "./footer/footer.component";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { MatMenuModule} from '@angular/material/menu';
import { MatIconModule } from "@angular/material/icon";
// import { AngularMaterialModule } from "../angular-material.module";
import { RouterModule } from "@angular/router";
import { AdvertisementComponent } from './advertisement/advertisement.component';


@NgModule({
  declarations: [
    Header2Component,
    FooterComponent,
    AdvertisementComponent
  ],
  exports: [
    Header2Component,
    FooterComponent,
    AdvertisementComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    // AngularMaterialModule,
    RouterModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {}
