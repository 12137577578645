<footer class="page-footer teal lighten-1">
  <div class="container">
    <div class="row">
      <div class="col s12 m6">
        <ul>
          <li><a routerLink="home" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Home</a></li>
          <li><a routerLink="schedule_of_events" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Schedule</a></li>
          <li><a routerLink="event_map" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Event Map</a></li>
          <li><a routerLink="fleece_show" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Fleece Show</a></li>
          <li><a routerLink="halter_show" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Halter Show</a></li>
          <li><a routerLink="judges" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Judges</a></li>
          <li><a routerLink="vet_on_site" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Vet On Site</a></li>
          <li><a routerLink="message_from_the_president" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">President's Message</a></li>
        </ul>
      </div>
      <div class="col s12 m6">
        <ul>
          <li><a routerLink="speakers" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Event Speakers</a></li>
          <li><a routerLink="sponsors" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Sponsors</a></li>
          <li><a routerLink="vendors" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Vendors</a></li>
          <li><a routerLink="volunteers" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Volunteers</a></li>
          <li><a routerLink="board_of_directors" class="grey-text text-lighten-3" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">Board of Directors</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container">
    © 2021 Copyright K1 Consulting LLC
    </div>
  </div>
</footer>
