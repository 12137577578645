
  <div class="col s12">
    <h4>Fleece Show</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->

          <p>
            In Fleece Shows, the fleece or fiber arts item is judged against a standard and given score points and written evaluation comments. This information is very useful to the alpaca owner in determining the best use for the fiber.
          </p>
          <h6>Divisions</h6>
          <ul>
            <li>
              Spin-off - 2 - 4 oz sample of prime blanket is spun and judged.
            </li>
            <li>
              Cottage - Spin-off score is combined with scores for remaining prime blanket.
            </li>
            <li>
              Fiber Arts - items made from alpaca fiber. Can be knitted, crocheted, woven or felted.
            </li>
            <li>
              Walking Fleece - Fleece is judged on the alpaca. Conformation is not included in evaluation and scoring.
            </li>
          </ul>








      <!-- <h1>INSERT TITLE HERE</h1>
       -->




          <!-- /main card content -->
        <!-- <div class="card-action">
          <a href="#">Edit</a>
        </div> -->
      </div>
    </div>
  </div>
