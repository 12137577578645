import { Component, OnInit, OnDestroy } from "@angular/core";


@Component({
  selector: "app-header2",
  templateUrl: "./header2.component.html",
  styleUrls: ["./header2.component.css"],
})
export class Header2Component implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  headerNavigationList;

  constructor() {}

  ngOnInit() {


      this.headerNavigationList = [
        {
          title: "Show Info",
          url: "show_info",
          subNav: [
            {
              title: "Schedule",
              url: "schedule_of_events"
            },
            {
              title: "Fleece Show",
              url: "fleece_show"
            },
            {
              title: "Halter Show",
              url: "halter_show"
            },
            {
              title: "Judges",
              url: "judges"
            },
            {
              title: "Vet on Site",
              url: "vet_on_site"
            },
          ]
        },
        {
          title: "Event Speakers",
          url: "speakers",
          subNav: []
        },
        {
          title: "Auctions",
          url: "auctions",
          subNav: []
        },
        {
          title: "Sponsors",
          url: "sponsors",
          subNav: []
        },
        {
          title: "Vendors",
          url: "vendors",
          subNav: []
        },
        {
          title: "Volunteers",
          url: "volunteers",
          subNav: []
        },
        {
          title: "About TXOLAN",
          url: "about_txolan",
          subNav: [
            {
              title: "President's Message",
              url: "message_from_the_president"
            },
            {
              title: "Board of Directors",
              url: "board_of_directors"
            },
          ]
        },
      ];

      // mobile side nav stuff
      var sidenav_elems = document.querySelectorAll('.sidenav');
      var sidenav_options = {
        edge: "right",
      };
      var sidenav_instances = window["M"].Sidenav.init(sidenav_elems, sidenav_options);

      // mobile side nav collapsible stuff
      var sidenav_collapsible_elems = document.querySelectorAll('.collapsible');
      var sidenav_collapsible_options = {
      };
      var sidenav_collapsible_instances = window["M"].Collapsible.init(sidenav_collapsible_elems, sidenav_collapsible_options);

      // nav tabs stuff
      var tabs = document.querySelectorAll('.tabs');
      var tabs_instance = window["M"].Tabs.init(tabs);

      // drop down stuff
      var dropdown_elems = document.querySelectorAll('.dropdown-trigger');
      var dropdown_options = {
        alignment: "right",
        container: "body",
        coverTrigger: false
      };
      var dropdown_instances = window["M"].Dropdown.init(dropdown_elems, dropdown_options);

      // show select stuff
      var showselect_elems = document.querySelectorAll('.show-select');
      var showselect_options = {};
      var showselect_instances = window["M"].FormSelect.init(showselect_elems, showselect_options);

      // show tab stuff
      var showtabs_elem = document.querySelectorAll('.tabs');
      var showtabs_options = {
      };
      var showtabs_instance = window["M"].Tabs.init(showtabs_elem, options);

      // mobile side nav stuff
      var sidenav_elems = document.querySelectorAll('.sidenav');
      var sidenav_options = {
        edge: "right",
      };
      var sidenav_instances = window["M"].Sidenav.init(sidenav_elems, sidenav_options);

      // mobile side nav collapsible stuff
      var sidenav_collapsible_elems = document.querySelectorAll('.collapsible');
      var sidenav_collapsible_options = {
      };
      var sidenav_collapsible_instances = window["M"].Collapsible.init(sidenav_collapsible_elems, sidenav_collapsible_options);

      // nav tabs stuff
      var tabs = document.querySelectorAll('.tabs');
      var instance = window["M"].Tabs.init(tabs);

        // drop down stuff
        var elems = document.querySelectorAll('.dropdown-trigger');
        var options = {
          alignment: "right",
          container: "body",
          coverTrigger: false
        };
        var instances = window["M"].Dropdown.init(elems, options);

  }

  onLogout() {
  }

  ngOnDestroy() {
  }
}
