

  <app-header2></app-header2>


  <main>
    <!-- <div class="row"> -->
      <!--
          <div class="col s2">
            <ul class="collection">
              <li *ngFor="let link of navigationList">
                  <a routerLink="{{link.url}}" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" class="collection-item black-text">
                    <div class="valign-wrapper">
                      <i class="material-icons">chevron_right</i> {{ link.title }}
                      {{ link.title }}
                    </div>
                  </a>
              </li>
            </ul>
          </div> -->

          <!-- <div class="col s12"> -->
            <!-- Teal page content  -->
            <!-- <div>Publicly Available Pages Wrapper:</div> -->









            <div class="row">
              <div class="col s12 hide-on-med-and-up">
                <app-advertisement></app-advertisement>
                <!-- <div class="card advertisement">
                  <div class="card-content">
                    <div class="center-align">
                      <img src="http://txolan2020.com/images/sponsors/nfp.png" />
                      <div class="grey-text">
                        Event Sponsor
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- page wrapper -->
              <router-outlet></router-outlet>
              <!-- /page wrapper -->
              <div class="col m5 l4 hide-on-small-only">
                <app-advertisement></app-advertisement>
                <!-- <div class="card advertisement">
                  <div class="card-content">
                    <div class="center-align">
                      <img src="http://txolan2020.com/images/sponsors/nfp.png" />
                      <div class="grey-text">
                        Event Sponsor
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>













          <!-- </div> -->

        <!-- </div> -->

  </main>




  <app-footer></app-footer>

