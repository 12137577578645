import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardPageComponent } from './pages/adminDashboard/adminDashboard.component';
import { AuctionsPageComponent } from './pages/auctions/auctions.component';
import { BoardOfDirectorsPageComponent } from './pages/board_of_directors/board_of_directors.component';
import { EventMapPageComponent } from './pages/event_map/event_map.component';
import { FleeceShowPageComponent } from './pages/fleece_show/fleece_show.component';
import { HalterShowPageComponent } from './pages/halter_show/halter_show.component';
import { HomePageComponent } from './pages/home/home.component';
import { JudgesPageComponent } from './pages/judges/judges.component';
import { MessageFromThePresidentPageComponent } from './pages/message_from_the_president/message_from_the_president.component';
import { PageComponent } from './pages/page/page.component';
import { ScheduleOfEventsPageComponent } from './pages/schedule_of_events/schedule_of_events.component';
import { SpeakersPageComponent } from './pages/speakers/speakers.component';
import { SponsorAdminDashboardPageComponent } from './pages/sponsorAdminDashboard/sponsorAdminDashboard.component';
import { SponsorsPageComponent } from './pages/sponsors/sponsors.component';
import { StartHerePageComponent } from './pages/start_here/start_here.component';
import { VendorDashboardPageComponent } from './pages/vendorDashboard/vendorDashboard.component';
import { VendorsPageComponent } from './pages/vendors/vendors.component';
import { VetOnSitePageComponent } from './pages/vet_on_site/vet_on_site.component';
import { VolunteersPageComponent } from './pages/volunteers/volunteers.component';

const routes: Routes = [

  { path: "", component: PageComponent,
    children: [
      { path: "", component: HomePageComponent, data: {'pageAlias': 'home'}  },
      { path: "home", component: HomePageComponent, data: {'pageAlias': 'home'}  },
      { path: "board_of_directors", component: BoardOfDirectorsPageComponent, data: {'pageAlias': 'board_of_directors'}  },
      { path: "message_from_the_president", component: MessageFromThePresidentPageComponent, data: {'pageAlias': 'message_from_the_president'}  },
      { path: "judges", component: JudgesPageComponent, data: {'pageAlias': 'judges'}  },
      { path: "schedule_of_events", component: ScheduleOfEventsPageComponent, data: {'pageAlias': 'schedule_of_events'}  },
      { path: "speakers", component: SpeakersPageComponent, data: {'pageAlias': 'speakers'}  },
      { path: "event_map", component: EventMapPageComponent, data: {'pageAlias': 'event_map'}  },
      { path: "fleece_show", component: FleeceShowPageComponent, data: {'pageAlias': 'fleece_show'}  },
      { path: "halter_show", component: HalterShowPageComponent, data: {'pageAlias': 'halter_show'}  },
      { path: "auctions", component: AuctionsPageComponent, data: {'pageAlias': 'auctions'}  },
      { path: "vet_on_site", component: VetOnSitePageComponent, data: {'pageAlias': 'vet_on_site'}  },
      { path: "volunteers", component: VolunteersPageComponent, data: {'pageAlias': 'volunteers'}  },
      { path: "sponsors", component: SponsorsPageComponent, data: {'pageAlias': 'sponsors'}  },
      { path: "vendors", component: VendorsPageComponent, data: {'pageAlias': 'vendors'} },
      { path: "admin", component: VendorsPageComponent, data: {'pageAlias': 'vendors'} },
      { path: "adminUserProfile", component: VendorsPageComponent, data: {'pageAlias': 'vendors'} },
      { path: "adminDashboard", component: AdminDashboardPageComponent, data: {'pageAlias': 'admin_dashboard'} },
      { path: "vendorDashboard", component: VendorDashboardPageComponent, data: {'pageAlias': 'vendor_dashboard'} },
      { path: "sponsorAdminDashboard", component: SponsorAdminDashboardPageComponent, data: {'pageAlias': 'sponsor_admin_dashboard'} },
      { path: "startHere", component: StartHerePageComponent, data: {'pageAlias': 'start_here'} },
    ]
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
