import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";

// import { PagessService } from "../pagess.service";
// import { Pages } from "../pages.model";
// import { mimeType } from "./mime-type.validator";
//

@Component({
  selector: "app-pages-board-of-directors",
  templateUrl: "./board_of_directors.component.html",
  styleUrls: ["./board_of_directors.component.css"]
})
export class BoardOfDirectorsPageComponent implements OnInit {

  isLoading = false;
  private directorsSub: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute
  ) {};

  ngOnInit() {


    }

}
