
    <div class="col s12">
      <h4>Event Map</h4>

    </div>
    <div class="col s12 m7 l8">
      <div class="card">
        <div class="card-content">
          <!-- main card content -->

          <a href="assets/img/maps/event_map.jpg"><img src="assets/img/maps/event_map_small.jpg" class="eventMap" /></a>


          <!-- <h1>INSERT TITLE HERE</h1>
           -->






            <!-- /main card content -->
        </div>
      </div>
    </div>
