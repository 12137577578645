<h1>INSERT TITLE HERE</h1>




<div class="row">
  <div class="col s12">
    <div class="row">
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">User Info</span>
            <p>John Doe</p>
            <p>username: johndoe@johndoefarms.com</p>
            <p>password: *********</p>
          </div>
        </div>
      </div>
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">Business Info</span>
            <p>
              Business/Ranch Name
            </p>
            <ul>
              <li>
                address 1<br />
                address 2<br />
                city, state zip
              </li>
              <li>
                phone number
              </li>
              <li>
                email
              </li>
              <li>
                http://www.website.com
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col s4">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">Registered Alpacas</span>
            <ul>
              <li>
                White Fluffyhead
              </li>
              <li>
                Brown Fluffyhead
              </li>
              <li>
                Oreo Fluffyhead
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col s12">
        <div class="card white darken-1 small">
          <div class="card-content">
            <span class="card-title">My Schedule</span>
            <table>
              <thead>
                <tr>
                    <th>Date + Time</th>
                    <th>Alpaca</th>
                    <th>Show Name</th>
                    <th>Location</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Friday 11:00am</td>
                  <td>White Fluffyhead</td>
                  <td>White Fleece Competition</td>
                  <td>Big Arena</td>
                </tr>
                <tr>
                  <td>Friday 1:00pm</td>
                  <td>Brown Fluffyhead</td>
                  <td>Brown Fleece Competition</td>
                  <td>Small Arena</td>
                </tr>
                <tr>
                  <td>Saturday 10:00am</td>
                  <td>Oreo Fluffyhead</td>
                  <td>Random Fleece Competition</td>
                  <td>Big Arena</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>










<div class="row">
  <div class="col s12">


<div class="row">
  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">My Schedule</span>
        <table>
          <thead>
            <tr>
                <th>Date + Time</th>
                <th>Alpaca</th>
                <th>Show Name</th>
                <th>Location</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Friday 11:00am</td>
              <td>White Fluffyhead</td>
              <td>White Fleece Competition</td>
              <td>Big Arena</td>
            </tr>
            <tr>
              <td>Friday 1:00pm</td>
              <td>Brown Fluffyhead</td>
              <td>Brown Fleece Competition</td>
              <td>Small Arena</td>
            </tr>
            <tr>
              <td>Saturday 10:00am</td>
              <td>Oreo Fluffyhead</td>
              <td>Random Fleece Competition</td>
              <td>Big Arena</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Vendor</span>
      </div>
    </div>
  </div>

  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Card Title</span>
      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Card Title</span>
      </div>
    </div>
  </div>



  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Card Title</span>
      </div>
    </div>
  </div>

  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Card Title</span>
      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Card Title</span>
      </div>
    </div>
  </div>



  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Card Title</span>
      </div>
    </div>
  </div>

  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Card Title</span>
      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col s4">
    <div class="card blue-grey darken-1 small">
      <div class="card-content white-text">
        <span class="card-title">Card Title</span>
        <p>I am a very simple card. I am good at containing small bits of information.
        I am convenient because I require little markup to use effectively.</p>
      </div>
      <div class="card-action">
        <a href="#">This is a link</a>
        <a href="#">This is a link</a>
      </div>
    </div>
  </div>



  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Card Title</span>
      </div>
    </div>
  </div>

  <div class="col s4">
    <div class="card white darken-1 small">
      <div class="card-content">
        <span class="card-title">Card Title</span>
      </div>
    </div>
  </div>

</div>

</div>
</div>
