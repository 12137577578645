
<div class="row">
  <div class="col s12 hide-on-med-and-up">
    <div class="card advertisement">
      <div class="card-content">
        <div class="center-align">
          <img src="http://txolan2020.com/images/sponsors/nfp.png" />
          <div class="grey-text">
            Event Sponsor
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col s12">
    <h4>INSERT TITLE HERE</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->





        <div class="my-3 p-3 bg-light-teal rounded shadow-sm">
          <h5>Auctions</h5>
          <p>
            </p><p>
              </p><h5>Silent Auction</h5>
              <p>
                The Silent Auction will be in the Northern side of Barn 2 near the Milking Parlor, as in prior years.
              </p>
            <p></p>
            <p>
              </p><h5>Live Auction</h5>
              <p>
                The Live Auction will take place on Saturday evening and is intended to be fun and interactive! This auction will be focused on only the higher valued items, such as the HIGHLIGHTED BREEDINGS, ANY DONATED ANIMALS and THE EDUCATION WITH A DESTINATION. As always, we are open to any and all donations from alpaca crafted or fashion products to ranch equipment and personal or family focused items (vehicles, boats, timeshare condos, vacation packages, etc.) Be creative and help our organization with your donation!
              </p>
            <p></p>
            <p>
              </p><h5>Auction Items</h5>
              Main auction items:
              <ul>
                <li>
                  Tierra Promitida Alpaca Auction
                </li>
                <li>
                  Red Granite Alpacas Breeding
                </li>
                <li>
                  West Penn Alpacas Breeding
                </li>
                <li>
                  Crescent Moon Alpacas Breeding
                </li>
                <li>
                  Education Destination
                </li>
                <li>
                  Bahama Vacation Getaway
                </li>
              </ul>
            <p></p>
            <p>
              Full list of auction items:

              </p><table id="auctionItems">
                <thead>
                  <tr>
                    <th>Donation</th>
                    <th>Donor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Alpaca Necklace</td>
                    <td>Susan Grunger-Gray</td>
                  </tr>
                  <tr>
                    <td>Satin Glass</td>
                    <td>Kim Bisceglia/Mccarty Creek Rnch</td>
                  </tr>
                  <tr>
                    <td>Marlin 45/70 Lever Action Hog Rifle</td>
                    <td>Charlie Smithers</td>
                  </tr>
                  <tr>
                    <td>Pellet Rifle/with scope</td>
                    <td>Pulse Ranches</td>
                  </tr>
                  <tr>
                    <td>Wine Bassket</td>
                    <td>Charlie Smithers</td>
                  </tr>
                  <tr>
                    <td>10 Bales Standlee Compressed Hay</td>
                    <td>NRS</td>
                  </tr>
                  <tr>
                    <td>10 Bags Standlee Pellet hay</td>
                    <td>NRS</td>
                  </tr>
                  <tr>
                    <td>3 Day Vacation Bahamas</td>
                    <td>Alpacas of Aledo</td>
                  </tr>
                  <tr>
                    <td>Education with a Destination</td>
                    <td>Cresent Moon Alpacas/Flying Dutchman</td>
                  </tr>
                  <tr>
                    <td>Cozy Gift Basket</td>
                    <td>Prairie States</td>
                  </tr>
                  <tr>
                    <td>socks</td>
                    <td>Choice Alpaca</td>
                  </tr>
                  <tr>
                    <td>Paca Buddy &amp; Puzzle</td>
                    <td>Choice Alpaca</td>
                  </tr>
                  <tr>
                    <td>socks</td>
                    <td>Choice Alpaca</td>
                  </tr>
                  <tr>
                    <td>Sonicare toothbrush, waterpick, and teeth whitening products</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Young Living package</td>
                    <td>Jean Krause</td>
                  </tr>
                  <tr>
                    <td>Trailer Wheel Balancer  one set</td>
                    <td>Centrimatic</td>
                  </tr>
                  <tr>
                    <td>Alpaca Weathervane</td>
                    <td>Reilling Ranch</td>
                  </tr>
                  <tr>
                    <td>6 Panel Custom Trough Feeders</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Lazy J Ranch Wear</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Bean Hay Supply</td>
                    <td>Jason Bean</td>
                  </tr>
                  <tr>
                    <td>Sire Breeding</td>
                    <td>Cresent Moon Alpacas</td>
                  </tr>
                  <tr>
                    <td>Sire Breeding</td>
                    <td>Red Granite Ranch Alpacas</td>
                  </tr>
                  <tr>
                    <td>Sire Breeding</td>
                    <td>West Penn Alpacas</td>
                  </tr>
                  <tr>
                    <td>Female Alpaca</td>
                    <td>Tierra Promitida Alpacas</td>
                  </tr>
                </tbody>
              </table>
            <p></p>
          <p></p>
      <!-- END MAIN CONTENT -->
      </div>









      <h1>INSERT TITLE HERE</h1>





          <!-- /main card content -->
        <!-- <div class="card-action">
          <a href="#">Edit</a>
        </div> -->
      </div>
    </div>
  </div>
  <div class="col m5 l4 hide-on-small-only">
    <div class="card advertisement">
      <div class="card-content">
        <div class="center-align">
          <img src="http://txolan2020.com/images/sponsors/nfp.png" />
          <div class="grey-text">
            Event Sponsor
          </div>
        </div>
      </div>
    </div>
  </div>
</div>









