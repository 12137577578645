
  <div class="col s12">
    <h4>Schedule of Events</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->



        <div class="">
          <h5>Wednesday, February 10, 2021</h5>
          <ul>
            <li>
              <strong>1:00PM – 5:00PM:</strong> volunteers working on set up
            </li>
          </ul>
          <hr>
          <h5>Thursday, February 11, 2021</h5>
            <ul>
              <li>
                <strong>1:00PM – 5:30PM:</strong> Check in for Early Arrivals, Check in for Walking Fleece and Color Check for Walking Fleece
              </li>
            </ul>
            <p><strong>CHECK-IN INSTRUCTIONS: Follow signs to south side, Health check will occur in your trailers.  Proceed to unload and immediately move animals to color check in arena.  There is  limited area and we need you to move out of main check in spaces quickly.</strong></p>
            <hr>
            <h5>Friday, February 12, 2021</h5>
            <ul>
              <li>
                <strong>8:00AM – 3:30PM:</strong> Check in for All Other Exhibitors
              </li>
              <li>
                <strong>9:00AM – 5:15PM:</strong> Education (see education page)
              </li>
              <li>
                <strong>9:00AM – 4:00PM:</strong> Open to the Public, EDUCATION CLASSES and Open Market Vendors
              </li>
              <li>
                <strong>8:00AM – 6:00PM:</strong> Alpaca Walking Fleece Show Judging
              </li>
              <li>
                <strong>9:00AM – 3:30PM:</strong> Check-in, Compliance and Color Check (arena)
              </li>
              <li>
                <strong>5:30PM:</strong> Meet & Greet Reception - North end of Arena (Great Appetizers / Cash Bar)  Mandatory Exhibitors Meeting
              </li>
            </ul>
            <hr>
            <h5>Friday, February 12, 2021 (SEMINAR SCHEDULE)</h5>
            <ul>
              <li>
                <strong>9:00AM – 9:45AM:</strong> Staci Forshee - Handling and training your alpacas.
              </li>
              <li>
                <strong>10:00AM – 11:30AM:</strong> (Alpaca Analysis)
                Critique an alpaca's stature from a Judge's and Breeder’s perspective. Let us discuss each section of the alpaca from top to bottom. What are allowable differences in each body part of the alpaca?
                Class will be held at ringside with alpacas for hands-on training.
              </li>
              <li>
                <strong>1:00PM – 2:30PM:</strong> (Practices of Placements and Judging Alpacas)
                Join Senior Judge Wade Gease for understanding ring assessments of alpacas and learning the practices in making placement decisions.
                Handouts will be provided and class will be ringside with alpacas for hands on training.
              </li>
              <li>
                <strong>3:00PM – 3:45PM:</strong> Skirting Fleece for Show Competition.
              </li>
              <li>
                <strong>4:00PM – 5:15PM:</strong> Barn Banter
                Small, medium and large farms as separate focus groups for open discussion and sharing ideas, issues, solutions and networking.
              </li>
              <li>
                <strong>5:30PM:</strong> Meet & Greet Reception - North end of Arena (Great Appetizers / Cash Bar)  Mandatory Exhibitors Meeting
              </li>
            </ul>
            <p><strong>EDUCATION FEES: $25.00 for all TxOLAN members. $50.00 for all non members. Per person price gives access to all seminars </strong></p>
            <hr>

            <h5>Saturday, February 13, 2021</h5>
            <ul>
              <li>
                <strong>8:00AM:</strong> Fleece Show Judging (closed to exhibitors and the public)
              </li>
              <li>
                <strong>8:00AM – 5:00PM:</strong>  Open to the Public  Halter Shows, Sweetheart Spectacular & Bluebonnet Stakes, Fleece Show, Silent Auction and Open Market Vendors
              </li>
              <li>
                <strong>12:00PM – 1:30PM:</strong>  Lunch Break - Annual Membership Meeting - Lunch served for members only.
              </li>
              <li>
                <strong>6:00PM:</strong> Banquet and Auction at The Wellington next to the Holiday Inn Express....until  10:00PM +/-.  New SURPRISE entertainment spectacular dinner in the banquet room and exciting live auction.  Full of knee slappin' fun.  Yeeehaw!
              </li>
            </ul>
            <hr>
            <h5>Sunday, February 14, 2021</h5>
            <ul>
              <li>
                <strong>8:00AM – Until Completed:</strong> Open to the Public  Both Halter Shows, Sweetheart Spectacular & Bluebonnet Stakes, Fleece Show Action, Silent Auction, Open Market Vendors
              </li>
              <li>
                <strong>8:00AM:</strong> "Mini-Clinic" for Youth Performance
              </li>
              <li>
                <strong>9:00AM – Until Completed:</strong>  YOUTH PERFORMANCE COMPETITION (Youth, 4H, FFA)
              </li>
              <li>
                <strong>11: 00AM:</strong> Silent Auction Closes
              </li>
              <li>
                <strong>12:00PM – 1:00PM:</strong>  Lunch
              </li>
            </ul>
            <hr>
            <h5>Monday, February 15, 2021</h5>
            <ul>
              <li>
                Must be loaded out by 12:00 Noon.
              </li>
            </ul>

      <!-- END MAIN CONTENT -->
      </div>







      <!-- <h1>INSERT TITLE HERE</h1>
      <p>{{dashboard.page.content}}</p>
       -->





          <!-- /main card content -->
        <!-- <div class="card-action">
          <a href="#">Edit</a>
        </div> -->
      </div>
    </div>
  </div>
