import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";


// import { AdminssService } from "../adminss.service";
// import { Admins } from "../admins.model";
// import { mimeType } from "./mime-type.validator";
//

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"]
})
export class AdminComponent implements OnInit {

  navigationList;
  constructor(
    private activatedRoute: ActivatedRoute,

  ) {};

  ngOnInit() {

    this.navigationList = [
      {
        title: "Home",
        url: "/"
      },
      {
        title: "Board of Directors",
        url: "board_of_directors"
      },
      {
        title: "Message from the President",
        url: "message_from_the_president"
      },
      {
        title: "Judges",
        url: "judges"
      },
      {
        title: "Schedule of Events",
        url: "schedule_of_events"
      },
      {
        title: "Speakers",
        url: "speakers"
      },
      {
        title: "Event Map",
        url: "event_map"
      },
      {
        title: "Fleece Show",
        url: "fleece_show"
      },
      {
        title: "Halter Show",
        url: "halter_show"
      },
      {
        title: "Auctions",
        url: "auctions"
      },
      {
        title: "Vet On Site",
        url: "vet_on_site"
      },
      {
        title: "Volunteers",
        url: "volunteers"
      },
      {
        title: "Sponsors",
        url: "sponsors"
      },
      {
        title: "Vendors",
        url: "vendors"
      }
    ]
  }
}
