import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";

// import { PagessService } from "../pagess.service";
// import { Pages } from "../pages.model";
// import { mimeType } from "./mime-type.validator";
//

@Component({
  selector: "app-pages-vendors",
  templateUrl: "./vendors.component.html",
  styleUrls: ["./vendors.component.css"]
})
export class VendorsPageComponent implements OnInit {

  isLoading = false;
  constructor(
    private activatedRoute: ActivatedRoute
  ) {};

  ngOnInit() {



    this.isLoading = true;

  }

}
