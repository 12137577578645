import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

// import { AngularMaterialModule } from "../angular-material.module";
import { HomePageComponent } from "./home/home.component";
import { PageComponent } from "./page/page.component";
import { BoardOfDirectorsPageComponent } from "./board_of_directors/board_of_directors.component";
import { MessageFromThePresidentPageComponent } from "./message_from_the_president/message_from_the_president.component";
import { JudgesPageComponent } from "./judges/judges.component";
import { ScheduleOfEventsPageComponent } from "./schedule_of_events/schedule_of_events.component";
import { SpeakersPageComponent } from "./speakers/speakers.component";
import { EventMapPageComponent } from "./event_map/event_map.component";
import { FleeceShowPageComponent } from "./fleece_show/fleece_show.component";
import { HalterShowPageComponent } from "./halter_show/halter_show.component";
import { AuctionsPageComponent } from "./auctions/auctions.component";
import { VetOnSitePageComponent } from "./vet_on_site/vet_on_site.component";
import { VolunteersPageComponent } from "./volunteers/volunteers.component";
import { SponsorsPageComponent } from "./sponsors/sponsors.component";
import { VendorsPageComponent } from "./vendors/vendors.component";
// import { FateMaterialModule } from "fate-editor";
// import { HeaderComponent } from "../header/header.component";
import { SharedModule } from "../shared/shared.module";
import { AdminComponent } from "./admin/admin.component";
import { AdminDashboardPageComponent } from "./adminDashboard/adminDashboard.component";
import { VendorDashboardPageComponent } from "./vendorDashboard/vendorDashboard.component";
import { StartHerePageComponent } from "./start_here/start_here.component";
import { SponsorAdminDashboardPageComponent } from "./sponsorAdminDashboard/sponsorAdminDashboard.component";



@NgModule({
  declarations: [
    PageComponent,
    HomePageComponent,
    BoardOfDirectorsPageComponent,
    MessageFromThePresidentPageComponent,
    JudgesPageComponent,
    ScheduleOfEventsPageComponent,
    SpeakersPageComponent,
    EventMapPageComponent,
    FleeceShowPageComponent,
    HalterShowPageComponent,
    AuctionsPageComponent,
    VetOnSitePageComponent,
    VolunteersPageComponent,
    SponsorsPageComponent,
    VendorsPageComponent,
    AdminComponent,
    AdminDashboardPageComponent,
    VendorDashboardPageComponent,
    StartHerePageComponent,
    SponsorAdminDashboardPageComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // AngularMaterialModule,
    RouterModule,
    // FateMaterialModule,
    SharedModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class PagesModule {}
