


  <div class="col s12">
    <h4>Judges</h4>

  </div>
  <div class="col s12 m7 l8">
    <div class="card">
      <div class="card-content">
        <!-- main card content -->

        <div class="center-align hide-on-large-only">
          <h5>Bluebonnet Stakes</h5>
        </div>
        <div class="hide-on-med-and-down">
          <h5>Bluebonnet Stakes</h5>
        </div>
        <hr/>
        <div class="row">
          <div class="col s12 l4">
            <div class="center-align">
              <img src="assets/img/judges/gease-wade.jpg">
            </div>
          </div>
          <div class="col s12 l8">
            <h5>Wade Gease</h5>
            <ul>
              <li><strong>Senior Halter Judge & Judge Trainer for All Certifications</strong></li>
              <li><strong>Certifications:</strong></li>
              <li><strong>Huacaya Halter, Suri Halter, Fleece, Walking Fleece, Performance</strong></li>
            </ul>
            <p>Wade Gease is an AOA certified senior alpaca judge, judge training instructor, presenter, designer and consultant/mediator for the worldwide alpaca industry. He was the previous co-owner of LondonDairy Alpacas for 18 years. He now unveils his own wholesale/retail product line as The Alpaca Guy</p>
            <p>Wade has been honored to be involved with so many different activities in the alpaca world: presenting his personal educational seminars at pen sales, consulting, commentating, coordinating for auctions, and evaluating for private alpaca breeders. A favored activity has been leading yearly educational trips throughout Peru. In 2021 he will host a Fiesta Alpaca trip in April because of a scheduled alpaca show in Cusco. Contact him to be put on his notification list.</p>
            <p>Wade was the first to produce a N.A. made alpaca bamboo fabric. Thereafter, he created an upscale clothing line highlighting alpaca fabric, Alpaca Couture. The company’s vision was to gain greater attention from a discriminating consumer and help the industry gain greater recognition as an attractive fiber for fashion. Fashion shows were presented in Las Vegas, Orange County, and San Diego. The business transitioned into the accessory lines of The Alpaca Guy. Wade helps with Fashion Show/ Auction coordinations at different venues and shows like AOA.</p>
            <p>Mr. Gease also forged ahead in making alpaca materials, designing alpaca products and has created a wet felting machine for home/farm use to empower ranches to make on the farm products, The Wet Felting Wizard. It has had over 25,000 views on YouTube and has sold as far as the Netherlands, France & South Africa.</p>
            <p>Wade earned an Associate’s Degree in Aeronautical Science, a Bachelor’s Degree in Chemistry, and a Masters Degree in Biochemistry. He is also a Camelid Fibre Sorter certified from Olds College Natural Fibre Centre, Canada. His past has given him experience in construction, was a certified flight instructor, chemistry instructor, co-owner of a gift and floral store, and a professional sales representative for multiple pharmaceutical companies. He enjoys scuba diving, home improvement projects, and anything related to alpaca fiber!</p>
          </div>
        </div>

        <div class="center-align hide-on-large-only">
          <h5>Fleece Show, Walking Fleece and Performance</h5>
        </div>
        <div class="hide-on-med-and-down">
          <h5>Fleece Show, Walking Fleece and Performance</h5>
        </div>
        <hr/>
        <div class="row">
          <div class="col s12 l4">
            <div class="center-align">
              <img src="assets/img/judges/vandenbosch-amanda.jpg">
            </div>
          </div>
          <div class="col s12 l8">
            <h5>Amanda Vandenbosh</h5>
            <ul>
              <li><strong>Senior Judge & Judge Trainer for All Certifications</strong></li>
              <li><strong>Certifications:</strong></li>
              <li><strong>Huacaya Halter, Suri Halter, Fleece, Walking Fleece, Performance</strong></li>
            </ul>
            <p>Amanda VandenBosch is a Senior AOA Halter and Fleece Judge and an Alpaca Judge Trainer and Instructor in the United States. Amanda qualified as an International Alpaca Judge trained in Peru at the International Alpaca Judging School (IAJS ) in 2000. She has judged shows in the United States, Canada, United Kingdom, New Zealand, Australia, Germany, Switzerland, Sweden, and Peru gaining an international perspective and true love for the art and science of alpaca judging.</p>
            <p>Amanda travels worldwide judging alpacas, instructing, presenting seminars and workshops to breeders and judges. Her “hands on” approach is greatly sought after. Amanda worked with the British Alpaca Society (BAS) for 7 years to help develop the Judge Training Program, and with the New Zealand Judges for continuous training. She has been honored with regular appointments as an AOA Senior Instructor and Judge Trainer in both Fleece and Halter in the USA.</p>
            <p>Amanda has spent many hours volunteering over the years at AOBA National Conferences and regional events. She has participated on numerous committees. She is past president of CALPACA a Regional Affiliate and past co-chair of the Judges Advisory Committee (JAC). Currently she acts as a liaison for the Judge Training & Certification Committee. Amanda has had the opportunity to work with some of the world’s leading experts in the study of alpacas.</p>
            <p>Amanda is a native of the United Kingdom. She traveled and worked for The National Stud (UK) Lindsay Park Stud (Australia) and Lanes End Farm (Kentucky, USA) the premier thoroughbred breeding operations worldwide. In 1991 she settled in the USA. For the last 23 years, Amanda has been involved with alpacas in all aspects.</p>
            <p>Amanda is also a co-author of the book “The Art & Science of Alpaca Judging” published by AOBA and used as a training tool. With her husband, Vince, and two sons, Cody and Hunter, they operate Flying Dutchman Alpacas, in Bend, Oregon, successfully raising both huacayas and suris. Along with raising high quality breeding stock, they also own a partnership in Spring Harvest Mill, completing the circle of taking fleece to processing and end use, with these fine products being offered for sale at their local business Tumalo Coffee House.</p>
          </div>
        </div>

        <div class="center-align hide-on-large-only">
          <h5>Sweetheart Spectacular</h5>
        </div>
        <div class="hide-on-med-and-down">
          <h5>Sweetheart Spectacular</h5>
        </div>
        <hr/>
        <div class="row">
          <div class="col s12 l4">
            <div class="center-align">
              <img src="assets/img/judges/pena-arturo.jpg">
            </div>
          </div>
          <div class="col s12 l8">
            <h5>Arturo Pena *</h5>
            <ul>
              <li><strong>Certifications:</strong></li>
              <li><strong>Huacaya Halter, Suri Halter, Fleece, Walking Fleece, Performance</strong></li>
            </ul>
            <p>Arturo Pena is from Arequipa, Peru. He has an educational background in animal science and management.  In 2003, he moved to the United States, since then he has been working as a herd manager for large alpaca farms operation.  His fascination for alpacas and its luxury product "The Fleece" motivated him to get into the AOBA Judging Apprentice Program.  He completed his training and become an AOBA Certified Halter, Performance and Fleece Judge for both Huacayas and Suris. Arturo, his wife Kat and son Nicholas recently moved to the East Coast area.</p>
            <p>Arturo believes that education and mentoring are the keys to promote and develop a strong Alpaca industry.</p>
          </div>
        </div>



























        <!-- <h1>INSERT TITLE HERE</h1>


        <ul>
          <li *ngFor="let j of judges">
            <ul>
              <li><strong>
                <img [src]="j.imagePath" [alt]="j.lastName">
              </strong></li>
              <li><strong>
                <strong>{{ j.firstName }} {{ j.lastName }}</strong>
              </strong></li>
              <li><strong>
                {{ j.className }}
              </strong></li>
              <li [innerHTML]="j.bio">
              </strong></li>
            </ul>
          </strong></li>
        </ul> -->






          <!-- /main card content -->
        <!-- <div class="card-action">
          <a href="#">Edit</a>
        </div> -->
      </div>
    </div>
  </div>
