import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";


// import { PagessService } from "../pagess.service";
// import { Pages } from "../pages.model";
// import { mimeType } from "./mime-type.validator";
//

@Component({
  selector: "app-pages-vet-on-site",
  templateUrl: "./vet_on_site.component.html",
  styleUrls: ["./vet_on_site.component.css"]
})
export class VetOnSitePageComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,

  ) {};

  ngOnInit() {

  }

}
